import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { IconNames } from '@blueprintjs/icons'
import { Icon } from '@blueprintjs/core'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { useLocalStorage } from 'browser/components/hooks/useLocalStorage'

import 'browser/components/atomic-elements/organisms/side-navigation-bar-folder/_side-navigation-bar-folder.scss'

const DEFAULT_IS_OPEN = true

/**
 * @uiComponent
 */
interface ISideNavigationBarFolderProps extends IBaseProps {
  label: string
  persistenceKey: string
}

export const SideNavigationBarFolder = (props: ISideNavigationBarFolderProps) => {
  const { children, className, label, persistenceKey } = props

  const [isOpen, setIsOpen] = useLocalStorage(persistenceKey, DEFAULT_IS_OPEN)

  const onToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className={classNames('u-flex u-flexDirectionColumn c-sideNavigationBarFolder', className)}
    >
      <button
        onClick={onToggle}
        className="c-sideNavigationBarFolder__label c-sideNavigationBarItem c-sideNavigationBarItem-label"
      >
        <span>{label}</span>
        <Icon
          icon={IconNames.CHEVRON_DOWN}
          className={classNames({
            'c-sideNavigationBarFolder__icon': true,
            'c-sideNavigationBarFolder__icon--open': isOpen,
          })}
        />
      </button>
      <div className="c-sideNavigationBarFolder__children">{isOpen && children}</div>
    </div>
  )
}
