import _ from 'lodash'
import React from 'react'
import classNames from 'classnames'
import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { EntityFooter } from 'browser/components/atomic-elements/atoms/footer/entity-footer'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { SelectField } from 'browser/components/atomic-elements/molecules/fields/select-field'
import { FormTable } from 'browser/components/atomic-elements/atoms/form-table/form-table'
import { LabelMarker } from './label-marker'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'

// TODO: need to refactor.  Too many callbacks
interface IDocumentImagingTemplateDetailPanelProps extends IBaseProps {
  entity: any
  focusIndex?: any
  options: any[]
  propertyIndex: any
  handleCancelClicked?: any
  handleChangePropertyFocus: any
  handleEntityChange: any
  handleFocusChange?: any
  handleMapNewValue: any
  handleRemoveLabel: any
  handleRemoveProperty: any
  handleLabelPathChange: any
  handlePathChange: any
  handleValueChange: any
  handleValueFormatChange: any
  handleTemplateNameChange: any
  handleMatchThresholdChange: any
}

interface IDocumentImagingTemplateDetailPanelStates {
  isSaving: boolean
}

export class DocumentImagingTemplateDetailPanel
  extends React.Component<IDocumentImagingTemplateDetailPanelProps, IDocumentImagingTemplateDetailPanelStates> {

  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
    }
  }

  public render() {
    const { entity, handleMapNewValue } = this.props
    const { isSaving } = this.state
    const { textExtractTemplate } = entity
    const { displayName } = textExtractTemplate.documentType
    const documentType = _.get(entity, 'textExtractTemplate.documentType')

    return (
      <div className='grid-block vertical u-overflowHidden'>
        <div className='grid-content'>
          <div className='grid-block shrink c-cardHeader'>
            <div className='c-cardHeader-item c-cardHeader-item--grow u-overflowHidden'>
              <h3 className='c-cardHeader-title u-ellipsis'>Template Properties</h3>
              <div className='c-label c-label--secondary c-cardHeader-description u-ellipsis'> {displayName} </div>
            </div>
          </div>
          <FormTable>
            <SelectField
              isDisabled={true}
              label={'Document Type'}
              value={{ label: documentType.displayName, value: documentType }}
            />
            <InputField
              isHorizontalLayout={true}
              label='Template Name'
              value={textExtractTemplate.templateName}
              onChange={this.props.handleTemplateNameChange}
            />
            <InputField
              isHorizontalLayout={true}
              label='Match Threshold'
              value={textExtractTemplate.matchThreshold}
              onChange={this.props.handleMatchThresholdChange}
            />
          </FormTable>
          {this.renderDataMappings(entity)}
          <HelpBlock>
            Draw rectangles around nearby labels to set borders for the area you wish to extract.  Borders define the value area, edges define the nearby labels.
          </HelpBlock>
          <div className='tr'>
            <Button
              buttonText="Map new value"
              className={classNames(
                Classes.BUTTON,
                Classes.SMALL,
                Classes.iconClass(IconNames.ADD),
                Classes.MINIMAL,
              )}
              onClick={handleMapNewValue}
            />
          </div>
        </div>
        <EntityFooter
          entity={entity}
          isVisible={entity.isDirty}
          isPrimaryButtonLoading={isSaving}
          onCancelButtonClick={this.handleCancelClicked}
          onPrimaryButtonClick={this.handleSaveClicked}
        />
      </div>
    )
  }

  ////////////////////////////
  ///////// Renderer /////////
  ////////////////////////////

  private renderDataMappings(entity) {
    const {
      handleChangePropertyFocus,
      handleEntityChange,
      handleRemoveLabel,
      handleRemoveProperty,
      handleLabelPathChange,
      handlePathChange,
      handleValueChange,
      handleValueFormatChange,
      options,
      propertyIndex,
      focusIndex
    } = this.props
    const dataMappings = _.get(entity, 'textExtractTemplate.dataMappings')
    return _.map(dataMappings, (dataMapping, index: number) => {
      const { path, labelMarkers, value, valueFormat } = dataMapping
      const isDisabled = index !== propertyIndex
      const removeSection = () => {
        handleRemoveProperty(index)
      }
      return (
        <Section
          bodyClassName={classNames({
            'is-focused': propertyIndex === index
          })}
          className='mt3'
          isCollapsable={true}
          headerControls={(
            propertyIndex !== index &&
              <Button
                buttonText='Focus'
                className={classNames(
                  'u-bumperLeft',
                  Classes.BUTTON,
                  Classes.SMALL,
                  Classes.MINIMAL
                )}
                onClick={() => handleChangePropertyFocus(index)}
              />
          )}
          reduceMargin={true}
          title={`Property ${index + 1}`}
        >
          <FormTable>
            <SelectField
              isDisabled={isDisabled}
              label='Label'
              onChange={handleLabelPathChange}
              options={options}
              value={path}
            />
            <InputField
              isDisabled={isDisabled}
              isHorizontalLayout={true}
              label='Path'
              value={path}
              onChange={handlePathChange}
            />
            <InputField
              isDisabled={isDisabled}
              isHorizontalLayout={true}
              label='Default Value'
              value={value}
              onChange={handleValueChange}
            />
            <InputField
              isDisabled={isDisabled}
              isHorizontalLayout={true}
              label='Value Format'
              value={valueFormat}
              onChange={handleValueFormatChange}
            />
            {index !== 0 && (
              <Button
                className={classNames(
                  'c-button--square c-abstractListItem-removeButton',
                  Classes.MINIMAL
                )}
                onClick={removeSection}
              >
                <Icon icon={IconNames.CROSS} />
              </Button>
            )}
            {_.map(labelMarkers, (labelMarker, markerIndex) => {
              let style = null
              if (markerIndex === focusIndex && propertyIndex === index) {
                style = {
                  backgroundColor: '#ECF3FF',
                }
              }
              return (
                <LabelMarker
                  handleEntityChange={handleEntityChange}
                  handleRemoveLabel={handleRemoveLabel}
                  index={markerIndex}
                  isDisabled={index !== propertyIndex}
                  labelMarker={labelMarker}
                  style={style}
                />
              )
            })}
          </FormTable>
        </Section>
      )
    })
  }

  ////////////////////////////
  ///////// Handlers /////////
  ////////////////////////////

  private handleSaveClicked = () => {
    this.setState({ isSaving: true })
    const { entity } = this.props
    entity.save().then(() => {
      /**
       * Because our document templates save very quickly on average,
       * we want to give the user some indication that something has
       * happened, so we display the processing indicator on the save bar
       * (mapped to isSaving) for at least 1 second
       */
      setTimeout(() => {
        this.setState({ isSaving: false })
      }, 1000)
    })
  }

  private handleCancelClicked = () => {
    const { entity, handleCancelClicked } = this.props
    entity.rollback()
    handleCancelClicked()
  }

}
