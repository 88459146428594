import _ from "lodash"
import { LRUCache } from "shared-libs/models/cache"

const TRANSLATION_CACHE_SIZE = 100
const DEFAULT_LOCALE = 'en'
// TODO(Bobbi): This should be filled in from secrets store on deploy
const API_KEY = 'AIzaSyA7qX8vBcsAFCtGlX-X_-TyKdWtP1y0qPU'

const translateEndpoint = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}&format=text`
const guessLanguageEndpoint = `https://translation.googleapis.com/language/translate/v2/detect?key=${API_KEY}`

class TranslationManagerImpl {
    // key is source locale code ":" source string, and value is translated string
    private cache = new LRUCache<string, string>(TRANSLATION_CACHE_SIZE)

    public async guessLanguage(text): Promise<string> {
        const guessRequest = new URL(guessLanguageEndpoint)
        guessRequest.searchParams.append('q', text)
        const result = await fetch(guessRequest.toString())
        if (result.status >= 400) {
            console.warn(`failed to invoke guess language endpoint: ${result.status}: ${await result.text()}`)
            return null
        }
        const json = await result.json()
        const data = json.data?.detections
        return data?.[0]?.[0]?.language
    }

    public translateString(text: string, sourceLocale: string, destLocale: string): Promise<string> {
        // 'en' should match 'en-US', though 'en-GB' should not match 'en-US'
        if (_.isEmpty(text)) {
            return Promise.resolve(text)
        }
        const targetLocale = destLocale ?? DEFAULT_LOCALE
        const commentLocale = sourceLocale ?? DEFAULT_LOCALE
        if (commentLocale.startsWith(targetLocale) || targetLocale.startsWith(commentLocale)) {
            return Promise.resolve(text)
        }
        const key = sourceLocale + ':' + text
        if (this.cache.has(key)) {
            return Promise.resolve(this.cache.get(key))
        }
        const requestUrl = new URL(translateEndpoint)
        if (!_.isEmpty(commentLocale)) {
            requestUrl.searchParams.append('source', commentLocale)
        }
        requestUrl.searchParams.append('target', targetLocale)
        requestUrl.searchParams.append('q', text)
        const headers = new Headers()
        return fetch(requestUrl.toString(), {
            headers
        })
            .then((response) => response.json())
            .then((translated) => {
                const translation = _.get(translated, 'data.translations.0.translatedText')
                this.cache.set(key, translation)
                return translation
            })
            .catch((e) => console.warn(e.message))
    }
}

export const TranslationManager = new TranslationManagerImpl()