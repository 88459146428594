import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import apis from 'browser/app/models/apis'
import { AutofillBlock } from 'browser/components/atomic-elements/atoms/autofill-block/autofill-block'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Image as ImageModel } from 'shared-libs/models/image'
import { TemplateEditor } from 'browser/components/atomic-elements/organisms/template-editor'

interface IDocumentImagingEditorProps extends IBaseProps {
  entity: any
  imagePath: string
  handleEntityChange: any
  handleFocusChange?: any
  focusIndex?: any
  pageIndex?: number
  onLabelMarkerCreate?: any
  propertyIndex: any
}

export const DocumentImagingEditor: React.FC<IDocumentImagingEditorProps> = (props) => {
  const { className, focusIndex, handleEntityChange, handleFocusChange,
    onLabelMarkerCreate, pageIndex, entity, imagePath, propertyIndex } = props

  const getImageFromEntity = () => {
    const imagesJSON = entity.get(imagePath)
    return _.map(imagesJSON, (img) => new ImageModel(apis, img, entity))
  }

  const [images, setImages] = useState<ImageModel[]>(getImageFromEntity())
  useEffect(() => {
    // TODO: need to do a deep comparision diff to trigger a re-draw
    // if (!images || entity.get(imagePath).length !== images.length) {
    if (images) {
      setImages(getImageFromEntity())
    }
  }, [entity])

  const image = images[pageIndex]
  const width = image.get('width')
  const height = image.get('height')
  const dataMappings = _.get(entity, 'textExtractTemplate.dataMappings', [])

  return (
    <div className={classNames('grid-block vertical u-bumperLeft--xl overflow-hidden relative', className)}>
      <AutofillBlock>
        <TemplateEditor
          className='u-noPrint'
          dataMappings={dataMappings}
          image={image}
          handleEntityChange={handleEntityChange}
          handleFocusChange={handleFocusChange}
          focusIndex={focusIndex}
          isInEditMode={false}
          padding={10}
          propertyIndex={propertyIndex}
          showOriginal={true}
          showOverlay={true}
          width={width}
          height={height}
          onLabelMarkerCreate={onLabelMarkerCreate}
          zoomLevel={0}
        />
      </AutofillBlock>
    </div>
  )
}

DocumentImagingEditor.defaultProps = {
  pageIndex: 0,
}
