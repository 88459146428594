import classNames from 'classnames'
import $ from 'jquery'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import Dropzone from 'react-dropzone'

import { EventType } from 'shared-libs/models/types/storyboard/storyboard-execution'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { SheetContext } from 'browser/components/atomic-elements/atoms/sheet/sheet-manager'
import { AddCheckCallSheet } from 'browser/components/atomic-elements/domains/trucking/add-check-call-sheet/add-check-call-sheet'
import { EntityAssociationsSheet } from 'browser/components/atomic-elements/organisms/entity/entity-associations/entity-associations-sheet'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import 'browser/components/atomic-elements/organisms/feed/_event.scss'
import 'browser/components/atomic-elements/organisms/feed/_feed.scss'

import { CommentInput } from 'browser/components/atomic-elements/organisms/feed/comment-input'

import { ChangeSetItem } from 'browser/components/atomic-elements/organisms/feed/document-events/change-set-item'
import { CheckCallItem } from 'browser/components/atomic-elements/organisms/feed/document-events/check-call-item'
import { CommentItem } from 'browser/components/atomic-elements/organisms/feed/document-events/comment-item'
import { EmailEventItem } from 'browser/components/atomic-elements/organisms/feed/document-events/email-event-item'
import { InvoiceSubmissionEventItem } from 'browser/components/atomic-elements/organisms/feed/document-events/invoice-submission-event-item'
import { InvoiceMailEventItem } from 'browser/components/atomic-elements/organisms/feed/document-events/invoice-mail-event-item'
import { ShareItem } from 'browser/components/atomic-elements/organisms/feed/document-events/share-item'

import { BreadcrumbItem } from 'browser/components/atomic-elements/organisms/feed/workflow-events/breadcrumb-item'
import { EditItem } from 'browser/components/atomic-elements/organisms/feed/workflow-events/edit-item'
import { CreateItem } from 'browser/components/atomic-elements/organisms/feed/workflow-events/create-item'
import { ExternalItem } from 'browser/components/atomic-elements/organisms/feed/workflow-events/external-item'
import { NotificationItem } from 'browser/components/atomic-elements/organisms/feed/workflow-events/notification-item'

import { ChangeTracker } from 'browser/components/atomic-elements/organisms/feed/change-tracker'
import { Owner } from 'shared-libs/generated/server-types/entity'
import apis from 'browser/app/models/apis'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { getFacilityId } from './util'
import { Entity } from 'shared-libs/models/entity'
import { ShareGroupItem } from './document-events/share-group-item'
import { SchemaIds } from 'shared-libs/models/schema'
import { convertFile, detectWebpSupport, getFileMimeType, getFileType } from 'browser/app/utils/image'
import { Classes, Colors, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

/**
 * @uiComponent
 */
interface IFeedProps extends IBaseProps {
  dataSet: any
  entityType?: string
  entity: any
  isOrderActivityFeed?: boolean
  /** Whether the comment input is enabled. Defaults to true. */
  enableCommentInput?: boolean
  /** NOTE: 'grouped' not yet supported on web. Defaults to individual. */
  timestampDisplayMode: 'grouped' | 'individual'
  emptyStateMessage?: string
  commentInputPlaceholder?: string
  workflowEvents?: any[]

  legacyEventsToShow: any[]
  workflowEventsToShow: EventType[]
  /** Path to look for an appointment destination location */
  appointmentDestinationPath?: string
}

interface IFeedState {
  feedEvents: any[]
  /* comments saved locally that the user as posted in this session, in case ES
   * results are too slow in showing user's own comments, to avoid confusion. */
  postedComments?: Entity[]
  isLoading: boolean
  pendingAttachment?: any
  commentSaveProgress?: number
  isSavingComment?: boolean

  showCommentsInFeed?: boolean // DEBUG - for legacyEventsToShow
  showBreadcrumbsInFeed?: boolean // DEBUG - for workflowEventsToShow
}

abstract class Event {
  val: any
  owner: Owner
  timestamp: string

  public constructor(init?: Partial<Event>) {
    Object.assign(this, init)
  }
}
class DocumentEvent extends Event {}
class WorkflowEvent extends Event {}
class ShareGroupEvent extends Event {
  val: DocumentEvent[]
}

export class Feed extends React.Component<IFeedProps, IFeedState> {
  public static defaultProps: Partial<IFeedProps> = {
    entityType: '/1.0/entities/metadata/feedEvent.json',
    enableCommentInput: true,
    timestampDisplayMode: 'grouped',
    emptyStateMessage: 'There is no history or comments yet.',
    workflowEventsToShow: [
      EventType.CREATE,
      EventType.EDIT,
      EventType.COMPUTATION,
      EventType.EXTERNAL,
      EventType.NOTIFICATION,
      EventType.SYNTHETIC,
      EventType.BREADCRUMB,
    ],
  }

  private static syntheticEventWhiteListProps = new Set(['core_storyboard_execution.status'])

  private dataSource: EntityDataSource
  private scrollArea: any
  private dropzone: Dropzone

  constructor(props) {
    super(props)
    this.state = {
      feedEvents: [],
      postedComments: [],
      isLoading: true,

      showCommentsInFeed: false,
      showBreadcrumbsInFeed: false,
    }
  }

  public componentDidMount() {
    this.dataSource = this.createDataSource(this.props.entity)
    this.dataSource.find()
  }

  public componentWillUnmount() {
    this.dataSource.dispose()
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.entity.uniqueId !== nextProps.entity.uniqueId ||
      (nextProps.dataSet && !nextProps.dataSet.isLoading)
    ) {
      this.dataSource.dispose()
      this.dataSource = this.createDataSource(nextProps.entity)
      this.dataSource.find()
    }

    const numPrevWorkflowEvents = this.props.workflowEvents && this.props.workflowEvents.length || 0
    const numCurrWorkflowEvents = nextProps.workflowEvents && nextProps.workflowEvents.length || 0
    if (this.props.entity.uniqueId == nextProps.entity.uniqueId && numCurrWorkflowEvents > numPrevWorkflowEvents) {
      this.handleScrollToBottom()
    }
  }

  public render() {
    const { className } = this.props

    return (
      <SheetContext.Consumer>
        {({ openOverlay }) => (
          <div
            className={classNames('c-detailPanel', className)}
            data-debug-id="details-comments-panel"
          >
            {this.renderFeedHeader()}
            {this.renderFeedBody()}
            {this.renderCommentInput(openOverlay)}
          </div>
        )}
      </SheetContext.Consumer>
    )
  }

  private createDataSource(entity) {
    const { dataSet, entityType } = this.props
    const values = [{ entityId: entity.uniqueId }]

    if (dataSet) {
      _.forEach(dataSet.entities, (content: any) => {
        values.push({ entityId: content.uniqueId })
      })
    }

    return new EntityDataSource({
      entityType: entityType,
      filters: [
        {
          path: 'feedEvent.entity',
          type: 'matchEdge',
          values,
        },
      ],
      orders: [
        {
          path: 'creationDate',
          type: 'descending',
        },
      ],
      refreshInterval: 5000,
    }).setOnChange(this.handleData)
  }

  private handleAttachmentDocumentClick = (openOverlay) => {
    const { dataSet, entity } = this.props
    const associatedEntitySchema = dataSet.entitySchema
    const associatedEntityDefaultValue = {
      document: {
        name: entity.dispatchOrder.identifier,
      },
    }
    openOverlay(
      <EntityAssociationsSheet
        associatedEntityDefaultValue={associatedEntityDefaultValue}
        associatedEntitySchema={associatedEntitySchema}
        entity={entity}
        edgePath="document.entity"
        isEdgeOnEntity={false}
        showFooter={false}
        size="xs"
        uiSchemaPath="uiSchema.web.entityCreationFlow"
      />
    )
  }

  private handleAddCheckCallClick = (openOverlay) => {
    const { entity } = this.props
    openOverlay(<AddCheckCallSheet order={entity} />)
  }

  private handleData = async () => {
    const { feedEvents } = this.state
    const newEvents = this.dataSource.entities

    const allEvents = await this.getAllEvents(newEvents)

    this.setState({ feedEvents: allEvents, isLoading: false }, () => {
      if (allEvents.length > feedEvents.length) {
        this.handleScrollToBottom(false)
      }
    })
  }

  private getAllEvents = async (feedEvents) => {
    const wrappedFeedEvents = this.getFeedEvents(feedEvents)
    const wrappedWorkflowEvents = await this.getWorkflowEvents()
    return _.reverse(this.sortEvents(wrappedFeedEvents.concat(wrappedWorkflowEvents)))
  }

  private getFeedEvents = (feedEvents) => {
    const { postedComments } = this.state
    const { legacyEventsToShow } = this.props

    const allLegacyEventsToShow = this.getLegacyEventsToShow()
    const filteredFeedEvents: Entity[] = _.isEmpty(legacyEventsToShow)
      ? feedEvents
      : _.filter(feedEvents, (entity) =>
          _.some(allLegacyEventsToShow, (uuid) => entity.hasMixin(uuid))
        )

    const allFeedEvents = _.uniqBy(_.concat(filteredFeedEvents, postedComments), 'uniqueId')

    return _.reduce(
      allFeedEvents,
      (acc, entity) => {
        const lastItem = _.last(acc)
        const owner = entity.get('owner')
        const event = new DocumentEvent({ val: entity, owner, timestamp: entity.get('creationDate') })
        const sameOwner = lastItem?.owner.user.entityId === owner.user.entityId

        if (entity.hasMixin(SchemaIds.SHARE) && sameOwner) {
          if (lastItem instanceof DocumentEvent && lastItem.val.hasMixin(SchemaIds.SHARE)) {
            // convert last share to a share group
            acc.pop()
            const shareGroupEvent = new ShareGroupEvent({ val: [lastItem, event], owner, timestamp: lastItem.timestamp })
            acc.push(shareGroupEvent)
          } else if (lastItem instanceof ShareGroupEvent) {
            // add to existing share group
            lastItem.val.push(event)
          } else {
            acc.push(event)
          }
        } else {
          // add event as-is
          acc.push(event)
        }
        return acc
      },
      []
    )
  }

  private getWorkflowEvents = async (): Promise<any> => {
    const { workflowEvents, entity } = this.props

    const filteredWorkflowEvents = _.uniqBy(_.filter(workflowEvents, x => x.id !== 'first_event'), (x) => x.id)
    const cleanedWorkflowEvents = this.cleanWorkflowEvents(filteredWorkflowEvents)

    // pre-fetch users and facilities, so we have complete ownership & location data
    const userIds = _.map(cleanedWorkflowEvents, (event) => event.createdBy.entityId)
    const facilityId = getFacilityId(entity)
    const allIds = _.compact(_.uniq([...userIds, facilityId]))
    await apis.getStore().getOrFetchRecords(allIds)

    return _.map(
      cleanedWorkflowEvents,
      (event) => new WorkflowEvent({ val: event, owner: this.getWorkflowEventOwner(event), timestamp: event.creationDate })
    )
  }

  private getWorkflowEventOwner(event): Owner | undefined {
    if (event?.createdBy) {
      const userEntity = apis.getStore().getRecord(event.createdBy.entityId)
      return _.get(userEntity, 'owner')
    }
  }

  private handlePostComment = async (comment) => {
    const { entity } = this.props
    const attachmentInfo = await this.createAttachmentInfo()

    this.setState({ isSavingComment: true })

    const saveProps = {
      onProgress: (commentSaveProgress) => this.setState({ commentSaveProgress })
    }

    return entity
      .addComment(comment, navigator.language, attachmentInfo, saveProps)
      .then((commentEntity: Entity) => {
        return commentEntity.waitUntilIdle()
      })
      .then((commentEntity: Entity) => {
        // append comment to state and recompute
        const { postedComments } = this.state
        const allComments = _.concat(postedComments, [commentEntity])
        this.setState({ postedComments: allComments }, this.handleData)
      })
      .finally(() => {
        this.setState({ isSavingComment: false, pendingAttachment: undefined })
      })
  }

  private async createAttachmentInfo() {
    const { pendingAttachment } = this.state
    if (!pendingAttachment) {
      return undefined
    }

    const uniqueId = uuidv4()
    const mimeType = await getFileMimeType(pendingAttachment);

    return {
      uniqueId,
      body: pendingAttachment,
      remoteFile: {
        uniqueId,
        name: pendingAttachment.name,
        type: getFileType(mimeType),
        uri: URL.createObjectURL(pendingAttachment),
      },
    }
  }

  private renderFeedItem = (wrappedEvent, index) => {
    const { feedEvents } = this.state

    const isFirst = index === 0
    const isLast = !isFirst && feedEvents.length - 1 === index

    if (wrappedEvent instanceof DocumentEvent) {
      return this.renderDocumentFeedItem(wrappedEvent, index, isFirst, isLast)
    } else if (wrappedEvent instanceof WorkflowEvent) {
      return this.renderWorkflowFeedItem(wrappedEvent, index, isFirst, isLast)
    } else if (wrappedEvent instanceof ShareGroupEvent) {
      return this.renderShareGroupItem(wrappedEvent, index, isFirst, isLast)
    }
  }

  private renderDocumentFeedItem = (wrappedEvent, index, isFirst, isLast) => {
    const mixins = wrappedEvent.val.activeMixins

    // TODO - nit, this could be done without repeated finds
    if (_.find(mixins, { entityId: SchemaIds.COMMENT })) {
      return this.renderCommentItem(wrappedEvent.val, index, isFirst, isLast)
    } else if (_.find(mixins, { entityId: SchemaIds.CHANGE_SET })) {
      return this.renderChangeSetItem(wrappedEvent.val, index, isFirst, isLast)
    } else if (_.find(mixins, { entityId: SchemaIds.CHECK_CALL })) {
      return this.renderCheckCallItem(wrappedEvent.val, index, isFirst, isLast)
    } else if (_.find(mixins, { entityId: SchemaIds.SHARE })) {
      return this.renderShareItem(wrappedEvent.val, index, isFirst, isLast)
    } else if (_.find(mixins, { entityId: SchemaIds.EMAIL })) {
      return this.renderEmailEvent(wrappedEvent.val, index, isFirst, isLast)
    } else if (_.find(mixins, { entityId: SchemaIds.INVOICE_SUBMISSION })) {
      return this.renderInvoiceSubmissionEvent(wrappedEvent.val, index, isFirst, isLast)
    } else if (_.find(mixins, { entityId: SchemaIds.INVOICE_MAIL })) {
      return this.renderInvoiceMailEvent(wrappedEvent.val, index, isFirst, isLast)
    }
  }

  private renderWorkflowFeedItem = (wrappedEvent, index, isFirst, isLast) => {
    switch (wrappedEvent.val.eventType) {
      case EventType.BREADCRUMB:
        return this.renderWorkflowBreadcrumbEvent(wrappedEvent, index, isFirst, isLast)
      case EventType.EDIT:
        return this.renderWorkflowEditEvent(wrappedEvent, index, isFirst, isLast)
      case EventType.COMPUTATION:
        return this.renderWorkflowEditEvent(wrappedEvent, index, isFirst, isLast)
      case EventType.CREATE:
        return this.renderWorkflowCreateEvent(wrappedEvent, index, isFirst, isLast)
      case EventType.EXTERNAL:
        return this.renderWorkflowExternalEvent(wrappedEvent, index, isFirst, isLast)
      case EventType.NOTIFICATION:
        return this.renderWorkflowNotificationEvent(wrappedEvent, index, isFirst, isLast)
      case EventType.SYNTHETIC:
        return this.renderWorkflowSyntheticEvent(wrappedEvent, index, isFirst, isLast)
      default:
        return undefined
    }
  }

  private renderWorkflowBreadcrumbEvent = (wrappedEvent, index, isFirst, isLast) => {
    const { entity } = this.props

    return (
      <AppNavigatorContext.Consumer key={wrappedEvent.val.id}>
        {({ settings }) => (
          <>
            {settings.isAdmin && (
              <BreadcrumbItem
                event={wrappedEvent.val}
                owner={wrappedEvent.owner}
                index={index}
                isFirst={isFirst}
                isLast={isLast}
                workflowEntity={entity}
              />
            )}
          </>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderWorkflowEditEvent = (wrappedEvent, index, isFirst, isLast) => {
    const { entity, appointmentDestinationPath } = this.props

    return <EditItem
      event={wrappedEvent.val}
      owner={wrappedEvent.owner}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      key={wrappedEvent.val.id}
      workflowEntity={entity}
      appointmentDestinationPath={appointmentDestinationPath}
    />
  }

  private renderWorkflowCreateEvent = (wrappedEvent, index, isFirst, isLast) => {
    const { entity, appointmentDestinationPath } = this.props

    return <CreateItem
      event={wrappedEvent.val}
      owner={wrappedEvent.owner}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      key={wrappedEvent.val.id}
      workflowEntity={entity}
      appointmentDestinationPath={appointmentDestinationPath}
    />
  }

  private renderWorkflowExternalEvent = (wrappedEvent, index, isFirst, isLast) => {
    const { entity } = this.props

    return (
      <AppNavigatorContext.Consumer key={wrappedEvent.val.id}>
        {({ settings }) => (
          <>
            {settings.isAdmin && (
              <ExternalItem
                event={wrappedEvent.val}
                owner={wrappedEvent.owner}
                index={index}
                isFirst={isFirst}
                isLast={isLast}
                key={wrappedEvent.val.id}
                workflowEntity={entity}
              />
            )}
          </>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderWorkflowNotificationEvent = (wrappedEvent, index, isFirst, isLast) => {
    const { entity } = this.props

    return <NotificationItem
      event={wrappedEvent.val}
      owner={wrappedEvent.owner}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      key={wrappedEvent.val.id}
      workflowEntity={entity}
    />
  }

  private renderWorkflowSyntheticEvent = (wrappedEvent, index, isFirst, isLast) => {
    const { entity } = this.props

    const isWhiteList = _.find(wrappedEvent?.val?.outputMappings, (mapping) =>
      Feed.syntheticEventWhiteListProps.has(mapping?.destination)
    )

    return isWhiteList ? (
      <EditItem
        event={wrappedEvent.val}
        owner={wrappedEvent.owner}
        index={index}
        isFirst={isFirst}
        isLast={isLast}
        key={wrappedEvent.val.id}
        workflowEntity={entity}
      />
    ) : undefined
  }

  private handleScrollToBottom = (shouldAnimate = true) => {
    const $detailPanel = $(ReactDOM.findDOMNode(this.scrollArea))
    const scrollHeight = $detailPanel.prop('scrollHeight')
    if (shouldAnimate) {
      $detailPanel.animate({ scrollTop: scrollHeight }, 'slow')
    } else {
      $detailPanel.scrollTop(scrollHeight)
    }
  }

  private handleSetScrollAreaReference = (ref) => {
    this.scrollArea = ref
  }

  private renderCommentItem(comment, index, isFirst, isLast) {
    return (
      <AppNavigatorContext.Consumer key={comment.uniqueId}>
        {({ settings }) => (
          <CommentItem settings={settings} isFirst={isFirst} isLast={isLast} item={comment} key={comment.uniqueId} />
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderChangeSetItem(changeSet, index, isFirst, isLast) {
    const { entity } = this.props
    return (
      <ChangeSetItem
        entity={entity}
        item={changeSet}
        isFirst={isFirst}
        isLast={isLast}
        key={changeSet.uniqueId}
      />
    )
  }

  private renderCheckCallItem(checkCall, index, isFirst, isLast) {
    return (
      <CheckCallItem item={checkCall} isFirst={isFirst} isLast={isLast} key={checkCall.uniqueId} />
    )
  }

  private renderShareGroupItem(wrappedEvent, index, isFirst, isLast) {
    const { entity } = this.props

    return (
      <ShareGroupItem
        entity={entity}
        shareGroup={wrappedEvent.val}
        isFirst={isFirst}
        isLast={isLast}
        key={index}
      />
    )
  }

  private renderShareItem(share, index, isFirst, isLast) {
    const { entity } = this.props
    return (
      <ShareItem
        entity={entity}
        shareItem={share}
        isFirst={isFirst}
        isLast={isLast}
        key={share.uniqueId}
      />
    )
  }

  private renderEmailEvent(emailEvent, index, isFirst, isLast) {
    const { entity } = this.props
    return <EmailEventItem entity={entity} item={emailEvent} isFirst={isFirst} isLast={isLast} />
  }

  private renderInvoiceSubmissionEvent(emailEvent, index, isFirst, isLast) {
    const { entity } = this.props
    return (
      <InvoiceSubmissionEventItem
        entity={entity}
        item={emailEvent}
        isFirst={isFirst}
        isLast={isLast}
      />
    )
  }

  private renderInvoiceMailEvent(event, index, isFirst, isLast) {
    const { entity } = this.props
    return <InvoiceMailEventItem entity={entity} item={event} isFirst={isFirst} isLast={isLast} />
  }

  private renderFeedHeader() {
    const showFeedControls = false
    if (showFeedControls) {
      return (
        <div className="grid-block shrink u-innerBumperTop--xs u-bumperLeft--lg u-innerBumperRight--lg u-borderBottom u-flex u-justifyContentSpaceBetween">
          <Button className={Classes.MINIMAL}>Visible to: Warrior Logistics</Button>
          <Button className={Classes.MINIMAL}>Events Type: All</Button>
        </div>
      )
    }
  }

  private sortEvents(events) {
    return _.sortBy(events, (e) => {
      // for ShareGroupEvents, use the creationDate of the first share event
      const event = _.isArray(e.val) ? e.val[0].val : e.val
      return moment(event.creationDate)
    }).reverse()
  }

  private getLegacyEventsToShow() {
    const { legacyEventsToShow, enableCommentInput } = this.props
    const { showCommentsInFeed } = this.state
    const additionalEventsToShow = []

    if (showCommentsInFeed || enableCommentInput) {
      additionalEventsToShow.push(SchemaIds.COMMENT)
    }

    return (legacyEventsToShow || []).concat(additionalEventsToShow)
  }

  private getWorkflowEventsToShow() {
    const { workflowEventsToShow } = this.props
    const { showBreadcrumbsInFeed } = this.state
    const additionalEventsToShow = []

    if (showBreadcrumbsInFeed) {
      additionalEventsToShow.push(EventType.BREADCRUMB)
    }

    return workflowEventsToShow.concat(additionalEventsToShow)
  }

  private cleanWorkflowEvents(events) {
    const changeTracker = new ChangeTracker()
    const PLACEHOLDER_ID = 'dummy_placeholder_event'
    const eventsToShow = this.getWorkflowEventsToShow()

    return events.map((event) => {
      const dupeEvent = _.cloneDeep(event)

      if (!_.includes(eventsToShow, event.eventType)) {
        return null
      }

      if (dupeEvent.outputMappings) {
        dupeEvent.outputMappings = dupeEvent.outputMappings.filter((mapping) => {
          const key = mapping.destination
          const value = mapping.value

          const hasChanged = changeTracker.isChangedSinceTracked(key, value)
          changeTracker.trackChange(key, value)

          return hasChanged
        })
      }

      return dupeEvent
    }).filter(e => !!e && e.id != PLACEHOLDER_ID )
  }

  private renderFeedBody() {
    const { children, emptyStateMessage } = this.props
    const { isLoading } = this.state
    if (isLoading) {
      return (
        <div className="c-detailPanel-scrollArea">
          <LoadingSpinner />
        </div>
      )
    }

    const renderedEvents = this.renderFeedItems()

    return (
      <div className="c-detailPanel-scrollArea" ref={this.handleSetScrollAreaReference}>
        {children}
        <div className="c-timelineEvents pt3">
          { renderedEvents }
          { renderedEvents.length === 0 && (
            <div className="u-textCenter u-bumperTop--xl u-bumperBottom--xl c-helpBlock">
              {emptyStateMessage}
            </div>
          ) }
        </div>
      </div>
    )
  }

  private renderFeedItems() {
    const { timestampDisplayMode } = this.props
    const { feedEvents } = this.state
    const groupingThresholdMs = 30 * 60 * 1000 // 30m

    if (timestampDisplayMode === 'individual') {
      return feedEvents.map(this.renderFeedItem).filter((x) => !!x)
    }

    if (!feedEvents.length) {
      return []
    }

    const collapsedGroups = []
    let currentGroup = [feedEvents[0]]

    for (let i = 1; i < feedEvents.length; i++) {
      const first = currentGroup[0]
      const next = feedEvents[i]
      const firstTime = Date.parse(first.timestamp)
      const nextTime = Date.parse(next.timestamp)

      if (nextTime - firstTime > groupingThresholdMs) {
        collapsedGroups.push(currentGroup)
        currentGroup = [next]
      } else {
        currentGroup.push(next)
      }
    }

    collapsedGroups.push(currentGroup)

    return _.transform(collapsedGroups, (acc, group, idx) => {
      acc.push(this.renderTimestamp(group[0].timestamp))
      for (const event of group) {
        acc.push(this.renderFeedItem(event, idx))
      }
    }, [])
  }

  private renderTimestamp(timestamp) {
    return (
      <div className="tc f7 mb2 black-50" key={timestamp}>
        {moment(timestamp).calendar(null, {
          sameDay: 'h:mm a',
          lastDay: '[Yesterday] [•] h:mm a',
          lastWeek: 'dddd, MMM D [•] h:mm a',
          sameElse: 'dddd, MMM D [•] h:mm a',
        })}
      </div>
    )
  }

  private renderCommentInput(openOverlay) {
    const { isOrderActivityFeed, enableCommentInput, commentInputPlaceholder } = this.props
    const { pendingAttachment } = this.state
    const addButtonDropdown = isOrderActivityFeed
      ? this.renderCommentInputDropdown(openOverlay)
      : null
    if (!enableCommentInput) {
      return null
    }
    return (
      <>
        <Dropzone
          data-debug-id="feed:comment-input-attachment"
          onDrop={this.handleDrop}
          activeStyle={{}}
          disableClick={true}
          multiple={false}
          ref={(ref) => (this.dropzone = ref)}
        />
        {this.renderCommentAttachmentPreview()}
        <CommentInput
          addButtonDropdown={addButtonDropdown}
          commentInputPlaceholder={commentInputPlaceholder}
          attachButtonEnabled={!pendingAttachment}
          onFocus={this.handleScrollToBottom}
          onAttach={this.handleAttachClick}
          onSubmit={this.handlePostComment}
        />
      </>
    )
  }

  private renderCommentAttachmentPreview() {
    const { pendingAttachment, isSavingComment, commentSaveProgress } = this.state
    if (!pendingAttachment) {
      return null
    }
    const progress = (commentSaveProgress * 100) | 0
    const progressString = isSavingComment ? ` (${progress}%)` : ''
    return (
      <div className="flex items-center ba b--light-gray">
        <Button isDisabled={isSavingComment} onClick={this.handleRemoveAttachment} className="">
          <Icon icon={IconNames.CROSS} color={Colors.RED1} />
        </Button>
        <span className="ml2 truncate flex-grow">{pendingAttachment.name}</span>
        {isSavingComment && <span>{progressString}</span>}
      </div>
    )
  }

  private handleRemoveAttachment = () => {
    this.setState({ pendingAttachment: undefined })
  }

  private renderCommentInputDropdown(openOverlay) {
    return (
      <Popover className="collapse">
        <ul className="c-dropdownList">
          <li
            className="c-dropdownList-item"
            onClick={() => this.handleAttachmentDocumentClick(openOverlay)}
          >
            Attach document
          </li>
          <li
            className="c-dropdownList-item"
            onClick={() => this.handleAddCheckCallClick(openOverlay)}
          >
            Add check call
          </li>
        </ul>
      </Popover>
    )
  }

  private handleDrop = async (files) => {
    // TODO: roughly copied from
    // apps/webapp/src/browser/components/json-elements/atoms/file-input/file-input.tsx,
    // refactor to a shared util.

    if (_.isEmpty(files)) {
      return
    }

    const originalFile = files[0]
    const isWebpSupported = await detectWebpSupport()

    const detectedMimeType = await getFileMimeType(originalFile)

    const isImage = /^image/.test(detectedMimeType)
    const shouldConvertToWebp = isImage && isWebpSupported
    const file = shouldConvertToWebp ? await convertFile(originalFile, 'image/webp') : originalFile

    this.setState({ pendingAttachment: file })
  }

  private handleAttachClick = () => {
    this.dropzone.open()
  }
}
