import _ from 'lodash'
import React from 'react'
import { findDOMNode } from 'react-dom'

import { getMinZoomLevel } from 'browser/app/utils/image'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'

const d3 = require('d3')

interface ITemplateEditorProps extends IBaseProps {
  backgroundColor?: string
  dataMappings?: any[]
  handleEntityChange: any
  handleFocusChange?: any
  propertyIndex?: number
  height: number
  focusIndex?: any
  image: any
  isInEditMode: boolean
  maxZoomLevel?: number
  onChange?: (value: any) => void
  onClose?: () => void
  onZoom?: (zoomLevel: number) => void
  onLabelMarkerCreate?: any
  padding: number
  pageIndex?: number
  showOriginal?: boolean
  showOverlay?: boolean
  width: number
  zoomLevel: number
}

interface ITemplateEditorState {
  isLoading: boolean
  newShape?: any
  src?: string
  transformations?: number
  zoomLevel: number
  valueHeight: number
  valueWidth: number
}

export class TemplateEditor extends React.Component<ITemplateEditorProps, ITemplateEditorState> {

  public static defaultProps: Partial<ITemplateEditorProps> = {
    backgroundColor: 'rgba(0,0,0,0)',
    maxZoomLevel: 3,
    onClose: _.noop,
    onZoom: _.noop,
    padding: 24,
    zoomLevel: 0,
  }

  private d3zoom: any
  private imageElement: any
  private imageWrapperElement: any
  private newShape: any
  private valueBox: any

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      valueHeight: props.height,
      valueWidth: props.width,
      zoomLevel: 1,
    }
  }

  public componentDidMount() {
    this.d3zoom = d3.zoom()
    d3.select(findDOMNode(this)).call(this.d3zoom)
    this.installSVGDragHandler()
    this.getNextStateForProps(this.props)
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.image !== nextProps.image ||
      this.props.isInEditMode !== nextProps.isInEditMode ||
      this.props.height !== nextProps.height ||
      this.props.width !== nextProps.width ||
      this.props.zoomLevel !== nextProps.zoomLevel ||
      this.isTransformationChanged(this.state.transformations, nextProps.image.transformations)) {
      this.getNextStateForProps(nextProps)
    }
  }

  public render() {
    const {
      backgroundColor,
      children,
      className,
      height,
      image,
      isInEditMode,
      onClose,
      width,
    } = this.props
    const rotation = isInEditMode ? image.getRotation() : 0
    const rotateTransform = `rotate(${rotation}, ${width / 2}, ${height / 2})`
    // TODO(louis):  bg-light-gray for document imaging
    return (
      <div className='relative'>
        <svg
          className={className}
          height={height}
          width={width}
        >
          <rect
            width={width}
            height={height}
            onClick={onClose}
            fill={backgroundColor}
          />
          <g
            className='js-rotationWrapper'
            transform={rotateTransform}
            width={width}
            height={height}
          >
            <g
              className='js-imageWrapper'
              ref={this.handleImageWrapperRef}
            >
              {this.renderImage()}
              {this.renderValueBox()}
              {children}
              {this.renderLabelMarkers()}
              {this.renderLabelBoundaries()}
              {this.renderNewShape()}
            </g>
          </g>
        </svg>
        {this.renderLoading()}
      </div>
    )
  }

  private renderValueBox() {
    const { dataMappings, image, propertyIndex } = this.props
    let xCoordinate = 0
    let yCoordinate = 0
    let x2Coordinate = image.width
    let y2Coordinate = image.height

    // Build the box here lol
    const mapping = dataMappings[propertyIndex]
    const labelMarkers = _.get(mapping, 'labelMarkers', [])
    _.forEach(labelMarkers, (marker) => {
      const { boundingBox, position, edge, text } = marker
      if (_.isNil(boundingBox)) {
        x2Coordinate = 0
        x2Coordinate = 0
        return
      }

      const { top, left, width, height } = boundingBox
      if (position === 'top') {
        if (edge === 'top') {
          yCoordinate = top
        } else if (edge === 'bottom') {
          yCoordinate = top + height
        } else {
          yCoordinate = top
        }
      } else if (position === 'bottom') {
        if (edge === 'top') {
          y2Coordinate = top
        } else if (edge === 'bottom') {
          y2Coordinate = top + height
        } else {
          y2Coordinate = top
        }
      } else if (position === 'left') {
        if (edge === 'left') {
          xCoordinate = left
        } else if (edge === 'right') {
          xCoordinate = left + width
        } else {
          xCoordinate = left
        }
      } else if (position === 'right') {
        if (edge === 'left') {
          x2Coordinate = left
        } else if (edge === 'right') {
          x2Coordinate = left + width
        } else {
          x2Coordinate = left
        }
      }
    })

    return (
      <rect
        ref={this.handleValueBoxRef}
        x={xCoordinate}
        y={yCoordinate}
        height={y2Coordinate - yCoordinate}
        width={x2Coordinate - xCoordinate}
        fill={'rgba(248, 153, 57, 0.5)'}
      />
    )
  }

  private renderImage() {
    const { isInEditMode, image, pageIndex, showOriginal } = this.props
    const previewImage = isInEditMode || showOriginal ? image : _.first(image.source)
    // if not inEidtMode and showOriginal, we show the original. In edit mode always
    // show the image src in the state
    let src = (!isInEditMode && showOriginal) ? image.jpgUri : this.state.src
    if (previewImage.pages > 1 && pageIndex) {
      src = previewImage.preview[pageIndex].uri
    }

    return (
      <image
        className='js-image'
        onLoadStart={this.handleLoadStart}
        onLoad={this.handleLoad}
        width={previewImage.width}
        height={previewImage.height}
        xlinkHref={src}
        ref={this.handleImageRef}
      />
    )
  }

  private renderLoading() {
    const { isLoading } = this.state
    if (isLoading) {
      return <LoadingSpinner />
    }
  }

  private renderLabelMarkers = () => {
    const { propertyIndex, dataMappings, handleEntityChange } = this.props
    const property = dataMappings[propertyIndex]
    const labelMarkers = _.get(property, 'labelMarkers', [])
    return _.map(labelMarkers, (marker, index) => {
      const { boundingBox } = marker
      const left = _.get(boundingBox, 'left', 0)
      const top = _.get(boundingBox, 'top', 0)
      const width = _.get(boundingBox, 'width', 0)
      const height = _.get(boundingBox, 'height', 0)

      const handleOnClick = () => {
        if (!this.hasMaxNumberOfBoundaries()) {
          _.set(marker, 'position', 'top')
          _.set(marker, 'edge', 'top')
          handleEntityChange()
        }
      }

      return (
        <rect
          onMouseOver={(event) => this.handleMouseOverRect(event, index)}
          onMouseOut={this.handleMouseOutRect}
          onClick={handleOnClick}
          fill='rgba(187,222,251, 0.5)'
          x={left}
          y={top}
          width={width}
          height={height}
        />
      )
    })
  }

  private renderLabelBoundaries = () => {
    const {
      propertyIndex,
      dataMappings,
      image,
    } = this.props
    const mapping = dataMappings[propertyIndex]
    const labelMarkers = _.get(mapping, 'labelMarkers', [])
    const boundaries = []
    _.forEach(labelMarkers, (marker) => {
      const { boundingBox, edge } = marker
      if (_.isNil(boundingBox)) {
        return
      }
      if (edge === 'top') {
        boundaries.push(
          <line
            x1='0'
            y1={boundingBox.top}
            x2={image.width}
            y2={boundingBox.top}
            stroke='#3ca9e0'
            strokeWidth='2'
            strokeDasharray='10'
          />
        )
      } else if (edge === 'bottom') {
        boundaries.push(
          <line
            x1='0'
            y1={boundingBox.top + boundingBox.height}
            x2={image.width}
            y2={boundingBox.top + boundingBox.height}
            stroke='#3ca9e0'
            strokeWidth='2'
            strokeDasharray='10'
          />
        )
      } else if (edge === 'left') {
        boundaries.push(
          <line
            x1={boundingBox.left}
            y1='0'
            x2={boundingBox.left}
            y2={image.height}
            stroke='#3ca9e0'
            strokeWidth='2'
            strokeDasharray='10'
          />
        )
      } else if (edge === 'right') {
        boundaries.push(
          <line
            x1={boundingBox.left + boundingBox.width}
            y1='0'
            x2={boundingBox.left + boundingBox.width}
            y2={image.height}
            stroke='#3ca9e0'
            strokeWidth='2'
            strokeDasharray='10'
          />
        )
      }
    })

    return boundaries
  }

  private handleMouseOverRect = (event, index) => {
    const { handleFocusChange } = this.props
    handleFocusChange(index)
  }

  private handleMouseOutRect = (event) => {
    const { handleFocusChange } = this.props
    handleFocusChange(null)
  }

  private renderNewShape() {
    const { newShape } = this.state
    if (!newShape) { return }
    return (
      <rect
        fill={'rgba(187,222,251, 0.5)'}
        x={newShape.x}
        y={newShape.y}
        width={newShape.width}
        height={newShape.height}
      />
    )
  }

  private handleImageRef = (ref) => {
    this.imageElement = ref
  }

  private handleImageWrapperRef = (ref) => {
    this.imageWrapperElement = ref
  }

  private handleValueBoxRef = (ref) => {
    this.valueBox = ref
  }

  private handleLoadStart = () => {
    this.setState({ isLoading: true })
  }

  private handleLoad = () => {
    this.setState({ isLoading: false })
  }

  private handleZoom = () => {
    const domNode = findDOMNode(this.imageWrapperElement)
    d3.select(domNode).attr('transform', d3.event.transform)
    this.props.onZoom(d3.event.transform.k)
  }

  private hasMaxNumberOfBoundaries = () => {
    const { dataMappings, propertyIndex } = this.props
    const mapping = dataMappings[propertyIndex]
    const labelMarkers = _.get(mapping, 'labelMarkers', [])
    const boundaries = _.size(labelMarkers)
    // _.forEach(labelMarkers, (marker) => {
    //   const { labelBorders } = marker
    //   boundaries = boundaries + labelBorders.length
    // })
    return boundaries > 3
  }

  private installSVGDragHandler() {
    const imageEditor = this

    function handleDragStart() {
      // console.log(`start x=${d3.event.x} y=${d3.event.y}`)
      if (imageEditor.hasMaxNumberOfBoundaries()) {
        return
      }

      imageEditor.setState({
        newShape: {
          height: 0,
          type: 'rectangle',
          width: 0,
          x: d3.event.x,
          y: d3.event.y,
        }
      })
    }

    function handleDrag() {
      if (imageEditor.hasMaxNumberOfBoundaries()) {
        return
      }

      const newShape: any = imageEditor.state.newShape
      newShape.height = Math.abs(d3.event.y - newShape.y)
      newShape.width = Math.abs(d3.event.x - newShape.x)
      imageEditor.setState({ newShape })
    }

    function handleDragEnd() {
      if (imageEditor.hasMaxNumberOfBoundaries()) {
        return
      }
      // console.log(`end x=${d3.event.x} y=${d3.event.y}`)
      const newShape = imageEditor.state.newShape
      const onLabelMarkerCreate = imageEditor.props.onLabelMarkerCreate
      onLabelMarkerCreate(newShape)
      imageEditor.setState({ newShape: null })
    }

    const domNode = findDOMNode(this.imageElement)
    d3.select(domNode).call(d3.drag()
      .on('start', handleDragStart)
      .on('drag', handleDrag)
      .on('end', handleDragEnd))
    const domNode2 = findDOMNode(this.valueBox)
    d3.select(domNode2).call(d3.drag()
      .on('start', handleDragStart)
      .on('drag', handleDrag)
      .on('end', handleDragEnd))
  }

  private getNextStateForProps(props) {
    const { isInEditMode, image, maxZoomLevel, padding, showOriginal, width, height } = props
    const isZoomable = !isInEditMode
    const previewImage = isInEditMode || showOriginal ? image : _.first(image.source)
    const rotation = isInEditMode ? image.getRotation() : 0
    const src = isInEditMode ? null : previewImage.uri
    const k = this.getMinZoomLevel(width, height, previewImage, padding, rotation)
    const zoomLevel = isInEditMode ? k : props.zoomLevel
    // we want to temporarily set zoom callback before calling d3zoom.transform
    this.d3zoom.scaleExtent([k, maxZoomLevel])
      .translateExtent([[0, 0], [previewImage.width, previewImage.height]])
      .on('zoom', this.handleZoom)
    d3.select(findDOMNode(this)).call(this.d3zoom.scaleTo, zoomLevel)
    // set zoom callback appropriately depending on state
    this.d3zoom.on('zoom', isZoomable ? this.handleZoom : null)
    // setting next state
    this.setState({
      isLoading: !src,
      src,
      transformations: _.cloneDeep(image.transformations),
      zoomLevel: k,
    })
    if (isInEditMode) {
      previewImage.previewUri.then((uri) => {
        this.setState({ isLoading: false, src: uri })
      })
    }
  }

  private getMinZoomLevel(containerWidth, containerHeight, image, padding, rotation) {
    if (rotation === 90 || rotation === 270) {
      return getMinZoomLevel(image, padding, containerHeight, containerWidth)
    } else {
      return getMinZoomLevel(image, padding, containerWidth, containerHeight)
    }
  }

  private isTransformationChanged(oldTransformation, newTransformation) {
    const oldRotateTransform = _.find(oldTransformation, { type: 'rotation' })
    const newRotateTransform = _.find(newTransformation, { type: 'rotation' })
    const oldThresholdTransform = _.find(oldTransformation, { type: 'threshold' })
    const newThresholdTransform = _.find(newTransformation, { type: 'threshold' })
    return !_.isEqual(oldRotateTransform, newRotateTransform) ||
      !_.isEqual(oldThresholdTransform, newThresholdTransform)
  }
}
