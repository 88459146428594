import { Tab } from '@blueprintjs/core'
import _ from 'lodash'
import React from 'react'

import { ViewsIndex as ViewsPage } from 'browser/app/pages/app/views'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import EntityDetailCard, {
  IEntityDetailCardProps
} from 'browser/components/atomic-elements/organisms/entity/entity-detail-card/entity-detail-card'
import { CustomDocumentTypesPage } from 'browser/components/domains/firm/custom-document-types-page'
import { AppBundleIds } from 'shared-libs/models/app-bundle'
import { Entity } from 'shared-libs/models/entity'
import { AppBundleProps, EntityProps, GeneralSettingsProps } from 'shared-libs/models/prop-constants'
import { SchemaUris } from 'shared-libs/models/schema'
import { createAppBundleIfBasedBundleForFirm } from './firm-utils'

interface IFirmDetailCardState {
  appBundle: Entity
  generalSettings: Entity
  mobileSettings?: Entity
  docSchemasToSave: object
  replaceDocSchemaMappings: object
  isSaving: any
  errors: any
}

export class FirmDetailCard extends React.Component<IEntityDetailCardProps, IFirmDetailCardState> {
  private appBundleDataSet: EntityDataSource
  private generalSettingsDataSet: EntityDataSource
  private mobileSettingsDataSet: EntityDataSource

  constructor(props) {
    super(props)
    this.state = {
      appBundle: null,
      docSchemasToSave: {},
      errors: null,
      generalSettings: null,
      mobileSettings: null,
      isSaving: false,
      replaceDocSchemaMappings: {}
    }

    this.appBundleDataSet = new EntityDataSource({
      entityType: SchemaUris.APP_BUNDLE,
      filters: [
        {
          path: AppBundleProps.FIRM,
          type: 'matchEdge',
          value: {
            entityId: props.entity.get(EntityProps.ID)
          }
        }
      ]
    }).setOnChange(this.handleAppBundleDatasetChange)

    this.generalSettingsDataSet = new EntityDataSource({
      entityType: SchemaUris.GENERAL_SETTINGS,
      filters: [
        {
          path: GeneralSettingsProps.FIRM_ID,
          type: 'match',
          value: props.entity.get(EntityProps.ID)
        }
      ]
    }).setOnChange(this.handleGeneralSettingsDatasetChange)

    this.mobileSettingsDataSet = new EntityDataSource({
      entityType: SchemaUris.MOBILE_SETINGS,
      filters: [
        {
          path: GeneralSettingsProps.FIRM_ID,
          type: 'match',
          value: props.entity.get(EntityProps.ID)
        }
      ]
    }).setOnChange(this.handleMobilelSettingsDatasetChange)
  }

  public componentDidMount() {
    this.appBundleDataSet.find()
    this.generalSettingsDataSet.find()
    this.mobileSettingsDataSet.find()
  }

  public handleAppBundleDatasetChange = () => {
    const { entities } = this.appBundleDataSet

    if (_.isEmpty(entities)) {
      const { entity } = this.props
      // this firm has not based dms bundle, create one
      return createAppBundleIfBasedBundleForFirm(AppBundleIds.DMS, entity).then((appBundle) =>
        this.setState({ appBundle })
      )
    }
    this.setState({ appBundle: _.first(entities) })
  }

  public handleGeneralSettingsDatasetChange = () => {
    const { entities } = this.generalSettingsDataSet
    this.setState({ generalSettings: _.first(entities) })
  }

  public handleMobilelSettingsDatasetChange = () => {
    const { entities } = this.mobileSettingsDataSet
    this.setState({ mobileSettings: _.first(entities) })
  }

  public render() {
    return (
      <EntityDetailCard
        {...this.props}
        additionalTabs={[ this.renderGeneralSettingsTab, this.renderMobileSettingsTab, this.renderDocumentTypesTab ]}
      />
    )
  }

  private renderDocumentTypesTab = () => {
    const { appBundle, generalSettings } = this.state

    if (!generalSettings || !_.get(generalSettings, 'generalSettings.allowDoctypeCustomization')) {
      return
    }

    return (
      <Tab
        id="entityCardDocumentTypes"
        key="document-types-tab"
        title="Document Types"
        className="grid-block vertical"
        panel={
          <div className="grid-block vertical c-appBody">
            <div className="c-card paper grid-block vertical">
              <CustomDocumentTypesPage entity={appBundle} />
            </div>
          </div>
        }
      />
    )
  }

  private renderGeneralSettingsTab = () => {
    const { generalSettings } = this.state

    if (!generalSettings) {
      return
    }
    const location = {
      pathname: '/settings/view/53f7d39a-a351-4eff-a92a-c4ebc52bdeb0',
      search: `?entityId=${generalSettings.get('uniqueId')}`
    }

    const match = {
      isExact: true,
      params: {
        viewId: '53f7d39a-a351-4eff-a92a-c4ebc52bdeb0'
      },
      path: '/settings/view/:viewId',
      url: '/settings/view/53f7d39a-a351-4eff-a92a-c4ebc52bdeb0'
    }

    return (
      <Tab
        className="grid-block vertical"
        id="entityCardGeneralSettings"
        key="general-settings-tab"
        title="General Settings"
        panel={<ViewsPage location={location} match={match} history={null} />}
      />
    )
  }

  private renderMobileSettingsTab = () => {
    const { mobileSettings } = this.state

    if (!mobileSettings) {
      return
    }
    const location = {
      pathname: '/settings/view/b862f0ee-7589-4674-b39b-54580ac52250',
      search: `?entityId=${mobileSettings.get('uniqueId')}`
    }

    const match = {
      isExact: true,
      params: {
        viewId: 'b862f0ee-7589-4674-b39b-54580ac52250'
      },
      path: '/settings/view/:viewId',
      url: '/settings/view/b862f0ee-7589-4674-b39b-54580ac52250'
    }

    return (
      <Tab
        className="grid-block vertical"
        id="entityCardMobileSettings"
        key="mobile-settings-tab"
        title="Mobile Settings"
        panel={<ViewsPage location={location} match={match} history={null} />}
      />
    )
  }
}
