import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { ViewRenderer } from 'shared-libs/components/view/renderer'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'

interface IEntityResultsMasterPanelProps extends IBaseProps {
  entitySchema: any
  onSelectEntity: (...any) => void
  searchQuery: string
  selection: any[]
}

interface IEntityResultsMasterPanelState {
  entities: any[]
  isLoading: boolean
}

export class EntityResultsMasterPanel
  extends React.Component<IEntityResultsMasterPanelProps, IEntityResultsMasterPanelState> {

  private dataSet: EntityDataSource

  constructor(props) {
    super(props)
    this.state = {
      entities: [],
      isLoading: true,
    }

    this.dataSet = new EntityDataSource({
      entityType: props.entitySchema.id,
      query: props.searchQuery
    })
    this.dataSet.setOnChange(this.handleCollectionChange)
    this.dataSet.find()
  }

  public componentWillUnmount() {
    this.dataSet.dispose()
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.searchQuery !== nextProps.searchQuery) {
      // TODO - is this even used? changing the query never calls this, page refreshes...
      this.dataSet.collection.query.setQuery(nextProps.searchQuery).setOffset(0)
      this.dataSet.collection.find()
    }
  }

  public render() {
    const { isLoading } = this.state
    if (isLoading) {
      return (
        <LoadingSpinner />
      )
    }
    return this.renderEntityTable()
  }

  private handleCollectionChange = (entities) => {
    this.setState({
      entities,
      isLoading: false,
    })
  }

  private handleLoadNextPage = () => {
    this.dataSet.handleLoadNextPage()
  }

  private renderEntityTable() {
    const { entities } = this.state
    const { entitySchema, onSelectEntity, selection } = this.props
    const entitySample: any = _.get(_.first(entities), 'data')
    const uiSchema = entitySample.resolveSubschemaByPath('uiSchema.web.searchResults').schema
    const rendererAction = {
      handleLoadNextPage: this.handleLoadNextPage,
      handleOnSearchResultClicked: onSelectEntity,
    }
    const rendererState = {
      entitySchema,
      searchResults: entities,
      selection,
      dataSet: this.dataSet
    }
    return (
      <div className='c-searchResultsMasterPanel c-searchResultsMasterPanel--singleEntity'>
        <ViewRenderer
          actions={rendererAction}
          componentsMap={ComponentsMap}
          state={rendererState}
          schema={uiSchema}
          api={apis}
        />
      </div>
    )
  }
}
