import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { renderEventTimestamp as renderEventTimestamp } from '../util'
import { EventChanges } from '../workflow-events/event-changes'

interface IChangeSetItemProps extends IBaseProps {
  entity: any
  item: any
  isFirst: boolean
  isLast: boolean
}

export class ChangeSetItem extends React.Component<IChangeSetItemProps, any> {

  public render() {
    const { className, item, isFirst } = this.props
    const { changeSet, creationDate } = item
    const { applyPatch, author } = changeSet

    const childNodes = [
      ..._.map(applyPatch, (patch: any, index) => this.renderPatch(patch, index)),
      this.renderMixinPatch(),
    ].filter(Boolean)

    return (
      <div
        className={classNames('c-timelineEvent', className)}
        key={item.uniqueId}
      >
        <div
          className={classNames('c-timelineEvent-line', className, {
            'c-timelineEvent-line--topCutOff': isFirst,
          })}
        />
        <div className='c-timelineEvent-dot' />
        <div className='c-timelineEvent-contentContainer'>
          <div className='c-timelineEvent-body'>
            <HelpBlock>
              <span className='b'>{author.displayName}</span> updated on {renderEventTimestamp(creationDate)}:<br />
              <EventChanges>
                {childNodes}
              </EventChanges>
            </HelpBlock>
          </div>
        </div>
      </div>
    )
  }

  private renderPatch(patch, index) {
    const content = this.renderPatchContent(patch, index)
    if (content) {
      return (
        <span key={index}>{content} <br /></span>
      )
    }
  }

  private renderPatchContent(patch, index) {
    const { entity } = this.props
    const path = _.compact(patch.path.split('/'))
    _.remove(path, (value) => value === '-')
    // Check to see if it is a mixin change and if it is branch out
    if (_.includes(path, 'mixins') && _.includes(path, 'active')) {
      return null
    }

    const isWhiteListed = this.isWhiteListed(entity, path)
    const isBlackListed = this.isBlackListed(path)
    if (!isWhiteListed || isBlackListed) {
      return null
    }
    const label = this.createReadableLabel(path)
    const value = this.resolveValue(patch.value, label)
    return this.formatPatchString(patch.op, label, value, index, path, patch)
  }

  private renderMixinPatch() {
    const { entity, item } = this.props
    const { changeSet } = item
    if (!changeSet.mixinPatch) {
      return
    }

    const { mixinPatch } = item.changeSet
    const { active, inactive } = mixinPatch
    if (_.isEmpty(active) && _.isEmpty(inactive)) {
      return
    }
    const activeDisplayName = _.map(active, (mixin) => mixin.displayName)
    const inActiveDisplayName = _.map(inactive, (mixin) => mixin.displayName)
    if (!_.isEmpty(activeDisplayName) && _.isEmpty(inActiveDisplayName)) {
      return (<span> Updated {entity.displayName} to {_.join(activeDisplayName, ', ')} <br /></span>)
    }
    if (_.isEmpty(activeDisplayName) && !_.isEmpty(inActiveDisplayName)) {
      return (<span> Removed {_.join(inActiveDisplayName, ', ')} from {entity.displayName} <br /></span>)
    }
    return (
      // tslint:disable-next-line:max-line-length
      <span> Updated {entity.displayName} from {_.join(inActiveDisplayName, ', ')} to {_.join(activeDisplayName, ', ')} <br /></span>
    )
  }

  private createReadableLabel(path) {
    const { entity } = this.props
    const labels = _.map(path, (key, index) => {
      const schemaEntity = entity.resolveSubschemaByValuePath(_.take(path, Number(index) + 1))
      // https://app.asana.com/0/476876133283832/589887674596168
      if (!schemaEntity) {
        console.warn(`Invalid path=${path.join('.')}`)
      }
      return _.get(schemaEntity, 'schema.label', '')
    })
    return _.compact(labels).join(' ')
  }

  private resolveValue(value, label = '') {
    if (!value && !_.isBoolean(value)) { return '' }
    if (_.isArray(value)) {
      const values = _.map(value, (item) => this.resolveValue(item))
      return _.compact(values).join(', ')
    } else if (_.isObject(value)) {
      if (value.value && value.unit) {
        return `${value.value} ${value.unit}`
      }
      if (value.displayName) {
        return value.displayName
      }
      // check if this is signature object
      if (this.isESignatureType(value)) {
        return `${value.signer} electronically signed as the ${label}`
      }

      return ''
    }
    return String(value)
  }

  private formatPatchString(operation, label, value, index, path, patch) {
    const { item } = this.props
    const { changeSet } = item
    switch (operation) {
      case 'add':
        if (this.isESignatureType(patch.value)) {
          return value
        }
        return `Added ${value} to ${label}`
      case 'remove':
        return `Removed ${label}`
      case 'replace': {
        if (_.includes(path, 'mechanicSignature')) {
          return `Updated Mechanic Signature`
        }
        const rawValue = _.get(changeSet.applyPatch[index], 'value', undefined)
        const rawPrevValue = _.get(changeSet.revertPatch[index], 'value', undefined)
        let prevValue = this.resolveValue(rawPrevValue)
        if (typeof(rawValue) === 'number') {
          value = _.round(value, 2).toFixed(2)
        }
        if (typeof(rawPrevValue) === 'number') {
          prevValue = _.round(prevValue, 2).toFixed(2)
        }
        return `Updated ${label} from ${prevValue} to ${value}`
      }
      case 'move':
        return `Moved ${value}`
      case 'copy':
        return ''
        // return `Copied ${value} to ${label}`
      case 'test':
        return `Tested ${value}`
      default:
        return 'Performed unknown operation'
    }
  }

  private isWhiteListed(entity, path) {
    const whiteList = entity.namespaces
    return _.includes(whiteList, path[0])
  }

  private isBlackListed(path) {
    return _.includes(path, 'projectedRoute') ||
      _.includes(path, 'attachments') ||
      _.includes(path, 'denormalizedProperties') ||
      _.includes(path, 'displayName') ||
      _.includes(path, 'view') ||
      _.includes (path, 'entityId') ||
      _.includes(path, 'schemas')
  }

  private isESignatureType(value) {
    return value.signed && !_.isNil(value.signer) && !_.isNil(value.signedDate)
  }
}
